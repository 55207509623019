import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`TabSwitcherGroup`}</inlineCode>{` is used to navigate through sections on a page.`}</p>
    <h2>{`TabSwitcherGroup tabs`}</h2>
    <p>{`TabSwitcherGroup tabs Tabs are rendered based on the tabs property.
The tabs property is an array of objects corresponding to a particular tab.`}</p>
    <h2>{`Default examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<TabSwitcherGroup
  tabs={[
    { name: 'Пн', active: true},
    { name: 'Вт' },
    { name: 'Ср' },
    { name: 'Чт', disabled: true },
    { name: 'Пт' },
    { name: 'Сб' },
    { name: 'Вc' },
  ]}
  onTabClick={(tab) => alert(\`You clicked on \${tab.name} tab!\`)}
  width="fit-content"
/>
`}</code></pre>
    <h3>{`Main component props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tabs`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Array<{ name: string, active: boolean, disabled: boolean, icon: string }>`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`[]`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`array of tabs to display`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onTabClick`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`() => {}`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`tab onClick event handler`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'auto'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`css property for switch tab group width`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`minTabWidth`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String/Number`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`css property for switch tab group tab minWidth`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'folderType'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`folderType`}</inlineCode>{` results in different tab button styles`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`scale`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'md'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`sm`}</inlineCode>{`, `}<inlineCode parentName="td">{`md`}</inlineCode>{`, `}<inlineCode parentName="td">{`lg`}</inlineCode>{` resulting in different tab switcher group sizes`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      